.currLocationError {
    text-align: center;
    margin-top: 10px;
    text-transform: none;
    color: #f00;
    font-weight: bold;
}

.MultipleGeoCodeResultsMessage {
    margin-left: 5px;
    padding-bottom: 5px;
    float: left;
}

.MultipleGeoCodeResultsMessage a.StoreFinderLink {
    font-size: 1.2em;
    display: list-item;
    list-style: square inside;
    font-weight: bold;
}

div.store-fields-main table {
    float: left;
    width: 87%;
}

.store-fields-main {
    margin: 0 0 20px;
}

.button-search {
    padding-left: 10px;
}

.bottom-store-finder p {
    float: right;
    text-align: left;
    width: 84%;
}

.store-fields-main input[type="text"] {
    border: 1px solid #ccc;
    font-size: 1.2em;
    padding: 14px 10px;
    width: 100%;
    height: auto;
}

.store-fields-main input[type="text"]:focus {
    border: 1px solid #aaa;
}

.Responsive .store-fields-main select:focus {
    border: 1px solid #aaa;
}

.store-fields-main select {
    border: 1px solid #ccc;
    font-size: 1.1em;
    padding: 8px;
    color: #333;
    width: 100%;
}

.store-fields-main .button {
    font-weight: normal;
    font-size: 1.1em;
    text-transform: uppercase;
    padding-top: 10.5px;
    padding-bottom: 10.5px;
}

.storefinderSearchButtons input {
    padding: 10px 0;
    font-size: 1.2em;
}

.store-fields-main .input-text {
    font-size: 1.2em;
    font-weight: normal;
    line-height: 2.7em;
    text-transform: capitalize;
    vertical-align: middle;
    text-align: right;
}

.store-fields-main table .right {
    color: #fff;
    float: left;
    margin-bottom: 10px;
    text-align: left;
    vertical-align: middle;
}

#GeoCodeFailureMessage {
    color: #ef2c21;
    font-weight: bold;
    margin-left: 0 !important;
}

.a-to-z-stores {
    overflow: hidden;
}

.store-finder-img {
    background: url("/images/core/store-finder.png") no-repeat scroll center center transparent;
    float: right;
    height: 95px;
    width: 150px;
    text-align: right;
}

.link-container {
    right: 0;
    position: absolute;
    bottom: 0;
}

.link-container a {
    display: inline-block;
    padding: 10px 16px;
    background-color: #000;
    color: #fff !important;
    float: right;
    font-size: 1.3em;
    text-align: center;
    width: 100%;
}

#StoreDetailsContainer h1 {
    font-size: 1.4em;
    padding: 15px 27px 15px 5px;
    width: 100%;
    font-weight: bold;
    position: relative;
}

#StoreDetailsContainer {
    float: left;
}

.StoreFinderFac {
    text-align: left;
}

.StoreFinderFac img {
    padding-bottom: 6px;
    padding-left: 5px;
    padding-right: 6px;
}

.StoreFinderFac ul {
    line-height: 20px;
    padding-top: 10px;
}

.StoreFinderFac ul li {
    padding-left: 5px;
}

#StoreDetailsText,
.Responsive #StoreDetailsWrapper .StoreDetailBottomActions,
.Responsive #StoreFinderResultsListing .backButton,
.StoreFinderFac {
    padding: 0 5px;
}

.Responsive #StoreDetailsWrapper .StoreDetailBottomActions,
.Responsive #StoreFinderResultsListing .backButton {
    margin-top: 20px;
    margin-bottom: 20px;
}

#StoreDetailsContainer h2 {
    background-color: #f1f1f1;
    display: block;
    font-size: 1.2em;
    padding: 6px;
    font-weight: normal;
}

.gm-style-iw {
    height: auto !important;
    width: auto !important;
    min-width: 270px;
}

.gm-style .gm-style-iw,
.gm-style .gm-style-iw > div,
.gm-style .gm-style-iw .StoreFinderBalloonContainer,
.gm-style .gm-style-iw .StoreFinderBalloonContainer div {
    font-size: 1em;
}

#storeFinderMap button {
    background-color: #fff !important;
    color: #000 !important;
}

#storeFinderMap .gm-ui-hover-effect {
    right: 0 !important;
    top: 0 !important;
}

.SeeOnMapContainer {
    display: none;
}

.StoreDetailBottomActions .Responsive #StoreDetailsWrapper .StoreFinderDirectionsButton.ShowInMapsBtn {
    display: none;
}

.StoreFinderResultsLinkMobile {
    display: none;
}

.StoreFinderResultsLink,
.StoreFinderResultsLinkMobile {
    cursor: auto;
    font-weight: bold;
}

.StoreFinderResultsDetailsLinkContainer:hover {
    text-decoration: underline;
}

.StoreFinderBalloonAttributes {
    padding-top: 5px;
    padding-bottom: 5px;
}

#StoreDetailsTop p {
    width: 100%;
}

.StoreFinderResultsDetailsLinkContainer {
    border-bottom: 1px solid #ccc;
    clear: both;
    padding-bottom: 10px;
    font-size: 1.1em;
}

#StoreFindResultsHeading {
    color: #fff;
    display: block;
    font-size: 1.6em;
    margin-bottom: 10px;
    overflow: hidden;
    padding: 8px !important;
    text-align: left;
    text-transform: capitalize;
}

#StoreFinderResultsListing {
    height: 730px;
}

#StoreFinderResultsListing .StoreFinderInner {
    border: 1px solid #ddd;
    padding: 5px 10px;
    height: 100%;
    overflow: auto;
}

div.SearchIcon {
    height: auto;
    width: auto;
}

div.SearchIcon img {
    margin-left: 10px;
    margin-right: 10px;
}

#SearchResultsMessage {
    float: right;
    width: 80%;
}

.Responsive .StoreFinderResultsDistance {
    float: right;
    margin-top: 10px;
    line-height: 2em;
    text-align: right;
    width: 90px;
    color: #909090;
}

.StoreFinderResultMessage {
    clear: both;
    padding: 6px;
    margin-bottom: 5px;
}

.StoreFinderResultLongMessage {
    padding: 12px 6px;
    margin: 10px 0;
}

.allLettersBrand {
    margin-bottom: 22px;
    text-align: center;
    border: 0;
    margin-top: 10px;
    padding: 5px 0;
}

.allLettersBrand a {
    border: 1px solid #fff;
    margin: 2px;
    padding: 3px 6px;
    font-weight: bold;
    font-size: 1.4em;
    display: inline-block;
}

.clear-but {
    float: right;
    padding-left: 30px;
    padding-right: 15px;
}

div.lettersBoxesColumn {
    margin: 10px 0;
}

div.lettersBoxesColumn table tr th.letTilte span {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    color: #737373;
    cursor: default;
    display: inline-block;
    font-size: 2em;
    padding: 5px 20px;
}

div.lettersBoxesColumn table tr {
    border-bottom: 1px solid #ccc;
}

div.lettersBoxesColumn table {
    width: 100%;
    clear: left;
}

.button-clear {
    padding-left: 5px;
}

.letTilte span a.disabled {
    font-size: 3em;
}

.letterBigTitle,
.greyBoxBrand {
    padding: 0;
}

#pageContent table {
    width: 100%;
}

.allLettersBrand a.disabled,
.allLettersBrand a.disabled:hover {
    background-color: #eee;
    cursor: auto;
    color: #aaa;
}

.result-message {
    color: #f00;
    width: 100%;
    text-align: center;
}

div.lettersBoxesColumn table tbody tr td {
    padding: 10px 0;
}

.letTilte {
    width: 12%;
    padding: 10px 0;
}

.letItems {
    padding: 2px 0;
}

.letItems a {
    padding: 2px 6px;
    display: inline-block;
    line-height: 1.5em;
}

.letItems a.disabled {
    display: none;
}

.letItems a:hover {
    text-decoration: underline;
}

.letTilte a.disabled {
    font-size: 3em;
    padding: 0 10px;
}

.StoreLocator .content {
    margin: 0 10px;
    width: 940px;
    border-left: 0;
    min-height: 1166px;
    border-right: 0;
}

.findstore {
    height: 100%;
    width: 850px;
    padding: 0 15px 10px 0;
    font-size: 1em;
}

.header .contacts .contactstore {
    font-weight: bold;
    float: left;
    width: 195px;
    display: none;
}

.toplinks li.storefinder {
    background: none;
    padding-bottom: 3px;
}

.toplinks li.storefinder a {
    margin-left: 0;
}

.storefini {
    margin: 12px;
}

.StoreFinder .sidebarcontainer {
    display: none;
}

.StoreFinder .contentwrap {
    float: left;
    width: auto;
    margin-left: 12px;
}

.StoreFinder h1,
.StoreFinder p {
    margin: 12px 24px 12px 12px;
}

.StoreFinder h2.stores {
    clear: both;
    margin-bottom: 1em;
    margin-left: 12px;
}

.StoreFinder .stores li {
    float: left;
    width: 172px;
    height: 11em;
    margin-left: 12px;
}

.StoreFinder .stores li p {
    font-weight: bold;
    margin: 0;
}

.StoreFinder .stores li address {
    font-size: 1.1em;
}

.StoreLocator .s-store-fix {
    padding-bottom: 5px;
    padding-top: 5px;
    width: 100%;
}

.StoreLocator .s-store-fix h1,
.StoreLocator .s-store-fix p {
    width: auto;
    margin: 0;
}

.StoreLocator .s-store-fix p {
    padding-top: 15px;
    line-height: 1.4em;
}

.StoreLocator .s-store-fix table {
    width: 850px;
    margin-left: 12px;
    margin-bottom: 1em;
    height: auto;
    display: inline-block;
}

.StoreLocator .s-store-fix td {
    font-size: 1.1em;
    padding: 3px;
    line-height: 1.3em;
}

.StoreLocator .s-store-fix td a,
.StoreLocator .s-store-fix td p {
    font-size: 1em;
    margin: 0 2px 0 0;
    font-weight: bold;
}

.StoreLocator .s-store-fix td.location_title {
    font-size: 1.2em;
    font-weight: bold;
    padding-top: 6px;
}

.StoreLocator .s-store-fix td.location_description {
    padding: 3px;
}

.StoreLocator .s-store-fix .bodyTextgry {
    font-size: 1.2em;
    font-weight: bold;
    margin-left: 12px;
}

.s-store-fix {
    float: left;
    font-weight: normal;
    height: auto;
    line-height: 120%;
    margin: 0;
    padding: 0;
    width: 850px;
}

.StoreLocator h1 {
    font-size: 2.3em;
}

.StoreAll h1 {
    margin-top: 10px;
    text-align: center;
}

.StoreLocator .findstore input,
.StoreLocator .findstore select {
    margin: 0 8px;
    padding: 4px;
}

.findstore .ImgButWrap a,
.findstore .ImgButWrap a:active,
.findstore .ImgButWrap a:visited,
.findstore .ImgButWrap a:link,
.findstore .ImgButWrap a:hover,
.findstore .ImgButWrap a:focus {
    padding: 5px 20px;
}

.countryfind select {
    padding: 3px;
}

.StoreLocator .s-store-fix #Table2 td {
    padding: 8px;
    width: 190px !important;
}

label {
    font-weight: normal !important;
}

.orlabel {
    font-weight: bold;
    margin: 20px 0;
    text-align: center;
}

.storefinderSearchButtons .button {
    font-size: 1em;
}

.searchstores .store-fields-main {
    background: #f5f5f5;
    padding: 5%;
}

.store-fields-main .right {
    margin-bottom: 2%;
}

.Responsive .store-fields-main .button {
    padding-top: 0;
}

.Responsive .store-fields-main .right {
    margin-right: 10px;
    color: #6d6d6d;
}

.Responsive .store-fields-main .rightlast {
    margin-right: 15px;
}

.Responsive .store-fields-main {
    margin-bottom: 15px;
}

.Responsive .searchstores .store-fields-main {
    padding: 30px;
    margin-top: 10px;
    border: 1px solid #ddd;
}

.Responsive .searchstores .store-fields-a-z {
    padding: 44px 12px 47px 46px;
}

.Responsive .A-zlist {
    padding-top: 10px;
}

.Responsive .A-zText {
    font-size: 1.3em;
    font-weight: bold;
}

.Responsive .Az-finder {
    padding: 28px 15px;
    border: 1px solid #c9c9c9 !important;
    float: left;
    width: 100%;
}

.Responsive .store-fields-main select,
.store-fields-main input[type="text"] {
    padding: 14px 10px;
}

.localities-input-container {
    display: block;
}

.localities-item {
    font-size: 1.1em;
    padding: 5px 5px 5px 30px;
    height: auto;
    line-height: 25px;
    cursor: pointer;
}

.localities-icon {
    top: 5px;
    left: 8px;
    width: 14px;
    height: 25px;
}

.Responsive .BoldTitle {
    font-weight: bold;
}

.Responsive .Storetitle {
    padding: 15px 20px 10px 10px;
}

.Responsive #Storeimage {
    position: relative;
    height: auto;
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
}

.Responsive .highlightit img {
    opacity: 0.9;
}

.Responsive .highlightit:hover img {
    opacity: 1;
}

.Responsive #Storetext {
    height: 61px;
    width: 100%;
    position: absolute;
    text-align: center;
    font-size: 1.2em;
    color: #fff;
    font-weight: bold;
    padding-top: 10px;
    bottom: 0;
}

.Responsive #Storetext a {
    color: #fff;
}

.Responsive #Storetext a:hover {
    text-decoration: underline;
}

.Responsive .storecountrytitle,
.Responsive .storemilestitle {
    margin-top: 14px;
    font-size: 1.2em;
}

.Responsive .StoreFinderLeft {
    margin-bottom: 30px;
}

.Responsive .StoreFinderRight {
    margin-bottom: 30px;
}

.Responsive #GeoCodeFailureMessage {
    float: left;
    margin-top: 10px;
}

.Responsive .MapDataArea {
    margin-bottom: 30px;
    padding: 0 15px;
}

.Responsive .GreyBorder {
    border: 1px solid #c9c9c9;
}

.Responsive .ImgButWrapResponsive {
    width: 100%;
    text-transform: none !important;
}

.Responsive .AtoZwrapper {
    margin-top: 10px;
}

.Responsive .storefinderSearchButtons {
    margin-top: 20px;
}

.Responsive .storefinderSearchButtons input {
    text-transform: uppercase;
    padding: 20px;
}

.Responsive .storefinderSearchButtons .geoLocateFeature input {
    padding: 10px;
    width: auto;
    max-width: 100%;
    white-space: normal;
}

.Responsive .storefinderSearchButtons .button > div {
    text-align: center;
}

.Responsive .store-fields-a-z .storefinderSearchButtons input {
    width: 100%;
    padding: 15.5px 5px;
}

.Responsive .store-fields-a-z .storefinderSearchButtons {
    margin: 0;
}

.Responsive .Responsiveaccountsdisplay {
    display: none;
}

.Responsive .MultipleGeoCodeResultsMessage {
    width: 100%;
    float: left;
}

.Responsive .store-fields-main .input-text {
    margin-top: 4px;
}

.Responsive #StoreFinderResultsMap,
.Responsive #StoreFinderResultsListing {
    margin-top: 15px;
}

.Responsive .Detailsicon {
    padding-right: 10px;
}

.Responsive .StoreFinderResultsLink,
.Responsive .StoreFinderResultsLinkMobile {
    font-size: 1.2em;
    margin-bottom: 10px;
    margin-top: 10px;
    width: calc(100% - 90px);
}

.Responsive .Mapresponsive {
    float: right;
}

.Responsive .map-wrap {
    border: 1px solid #c9c9c9 !important;
}

.Responsive .Storesubtext {
    font-size: 0.5em;
}

.Responsive #StoreDetailsContainer {
    margin-top: 15px;
}

.Responsive .StoreDetailsContainerInner {
    min-height: 730px;
    border: 1px solid #c9c9c9;
    padding: 5px 10px;
}

.Responsive #StoreDetailsContainer .InnerStoreDetails,
.Responsive #StoreFinderResultsListing .InnerStoreDetails {
    border: 1px solid #c9c9c9;
    color: #5f5f5f;
}

.Responsive #StoreDetailsWrapper a.StoreFinderDirectionsButton {
    display: none;
}

.Responsive .Storefirstcollum {
    border-right: 1px solid #ccc;
    padding-right: 15px;
    margin-top: 20px;
    min-height: 140px;
}

.Responsive .Storesecondcollum {
    padding-left: 20px;
    margin-top: 20px;
}

.Responsive #StoreDetailsText .Storesecondcollum.ConcessionStoresWrapper {
    padding: 0;
}

.Responsive .Storeicon {
    padding-right: 5px;
    padding-bottom: 10px;
}

.Responsive .Storecalltext {
    color: #5f5f5f !important;
    font-size: 1em;
}

.Responsive .Storeicondisplay {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}

.storedetails.searchstores {
    padding-top: 10px;
}

.Responsive .StoreFinderLink {
    text-decoration: underline;
}

.Responsive .UnderlineitStorefinder {
    margin: 20px 0 0;
    border-bottom: 1px solid #e5e5e5;
}

.Responsive .StoreDetailsAddress {
    margin-bottom: 20px;
}

.Responsive .StoreFinderA-Z {
    padding-right: 5px;
}

.Responsive .StoreFinderBalloonFirst img {
    padding-right: 5px;
}

@media (min-width: 1022px) {
    .Responsive .storecountrytitle,
    .Responsive .storemilestitle {
        text-align: right;
    }

    .Responsive .storefinderSearchButtons input {
        width: 40%;
    }
}

@media (max-width: 1180px) {
    .Responsive .searchstores .store-fields-main {
        padding: 20px;
    }
}

@media (max-width: 1021px) {
    .Responsive .Storesecondcollum {
        padding-left: 5px;
    }

    .Responsive .storefinderSearchButtons .FindStores input {
        width: 60%;
    }

    .Responsive .Storefirstcollum {
        border-right: 0;
        padding-right: 5px;
        min-height: 0;
    }

    .Responsive .store-fields-main .button {
        margin-top: 25px;
    }

    .Responsive .Az-finder {
        padding: 10px !important;
    }
}

@media (max-width: 767px) {
    .StoreFinderResultsLinkMobile {
        display: block;
    }

    .StoreFinderResultsLink {
        display: none;
    }

    .Responsive .store-fields-main .button {
        margin-top: 10px;
    }

    .Responsive .storefinderSearchButtons .FindStores input {
        width: 100%;
    }

    #StoreDetailsText .ImgButWrap.button {
        width: 100%;
        margin: 20px 0;
    }

    .clear-but {
        margin-top: 10px;
    }

    .Responsive #StoreDetailsTop h2 {
        text-align: center;
    }

    .Responsive .StoreFindResultsHeading {
        text-align: center;
    }

    .Responsive .StoreFinderResultsDetailsLinkContainer a {
        background: #0463c4 !important;
        color: #fff !important;
        width: 100%;
        display: inline-block;
        padding: 15px 5px;
    }

    .Responsive .StoreFinderResultsDetailsLinkContainer {
        text-align: center;
    }

    .Responsive #StoreFinderResultsListing {
        height: auto;
        margin: 15px 0;
    }

    .Responsive .lettersBoxesColumn table,
    .Responsive .lettersBoxesColumn thead,
    .Responsive .lettersBoxesColumn tbody,
    .Responsive .lettersBoxesColumn th,
    .Responsive .lettersBoxesColumn td,
    .Responsive .lettersBoxesColumn tr {
        display: block;
    }

    .Responsive .lettersBoxesColumn td {
        border: none;
        position: relative;
        width: 100%;
        padding: 0;
        text-align: center;
    }

    .Responsive .lettersBoxesColumn td.divider {
        clear: both;
        width: 100%;
    }

    div.lettersBoxesColumn table tr {
        border-bottom: 0;
    }

    .Responsive .lettersBoxesColumn .letTilte {
        width: 100%;
    }

    .Responsive .lettersBoxesColumn .letTilte a {
        width: 100%;
        text-align: center;
    }

    .Responsive .lettersBoxesColumn .letItems a {
        padding: 5px 0;
        display: block;
    }

    .Responsive .allLettersBrand a {
        margin: 0;
        padding: 5px 12px;
    }

    .Responsive div.lettersBoxesColumn table tr th.letTilte span {
        width: 100%;
        text-align: center;
    }

    .Responsive .button-search {
        padding-left: 5px;
        margin: 30px 0 10px;
    }

    .Responsive .store-fields-main .input-text {
        text-align: left;
    }

    .Responsive .A-zlist {
        display: none;
    }

    .Responsive #StoreDetailsContainer {
        margin: 15px 0 !important;
        text-align: center;
    }

    .Responsive #StoreDetailsContainer h1 {
        text-align: center;
        padding: 15px 27px;
    }
}

.ShowInGoogleMaps,
.showInMapsBreak {
    display: none;
}

#StoreFinderResultsMap {
    overflow: hidden;
}

#GoogleStaticStoreMapContainer {
    margin-left: 50%;
    transform: translateX(-50%);
}

#GoogleStaticStoreMap {
    height: 728px;
    width: 100%;
    display: none;
}

/* StoreConcessions */
.ConcessionStoresWrapper .Title {
    padding: 10px 0;
}

.ConcessionStoresWrapper .Logos {
    display: flex;
}

.ConcessionStoresWrapper .Logos > div {
    padding: 0 10px 5px 0;
}

.PrimaryStoreWrapper .WithinMainStore {
    display: flex;
    margin: 10px 0;
}

.PrimaryStoreWrapper .WithinMainStore .Title {
    margin-right: 10px;
}

@media (max-width: 767px) {
    .ConcessionStoresWrapper .Logos {
        justify-content: center;
    }

    .ConcessionStoresWrapper .Logos > div {
        padding: 0 5px 5px;
    }

    .PrimaryStoreWrapper .WithinMainStore {
        justify-content: center;
    }
}
